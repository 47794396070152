import React from "react";
import HeadSeo from "../components/HeadSeo.js";
import Layout from "../components/Layout.js";
import DynamicSidebar from "../components/DynamicSidebar";
import Default from "../_contenthub/website.json";
const { termsPage } = Default;

export const Head = () => {
  return (
    <HeadSeo
      title="Terms & Conditions"
      description="Our current terms and conditions for using our website."
      seoImg={`search-icon.png`}
    ></HeadSeo>
  );
};

const TermsPage = () => {
  return (
    <>
      <Layout>
        <main>
          <div className="main-wrapper">
            <div className="primary-wrapper">
              <div className="layout-rightsidebar">
                <div className="main backpage">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: termsPage?.bodytext,
                    }}
                  />
                </div>
                {/* - - -  */}
                <DynamicSidebar onlyBlogs="true" />
              </div>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default TermsPage;
